<template>
  <div class="home">
    <div class="title">
      <h1 class="langCng" lang="ja" style="display:none;">あなたはどの言語を利用していますか？</h1>
      <h1 class="langCng" lang="en" >Which language are you using?</h1>
    </div>
    <div  class="lang">
      <div class="lang_box">
          <router-link to="./EN/top" class="lang_rlink">
            <div class="lang_box_word">
              <p class="lang_word">English</p>
            </div>
          </router-link>
      </div>
      <div class="lang_box">
          <router-link to="./" class="lang_rlink">
            <div class="lang_box_word">
              <p class="lang_word">日本語(準備中)</p>
            </div>
          </router-link>
      </div>
    </div>
  </div>
</template>

<style>
.page_link {
  text-decoration: none;
  color:rgb(0,119,248);
}
.page_link:hover {
  background-color:rgba(143,221,245,0.4);
}

.home {
  max-width:900px;
  width:auto !important;
  width:800px;
}
.title {
  border-left-width:5px;
  border-left-color:Blue;
  border-left-style:solid;
  padding: 0 0 0 5px;
}

.lang_word {
  position: absolute;
  font-size: 16px;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lang_box {
  margin: 10px;
}

.lang_rlink {
  text-align:center;
  color:#2c3e50;
}

.lang {
  display: -webkit-flex;
  display: flex;
   -webkit-justify-content: space-around;
  justify-content: space-around;
    -webkit-align-items: flex-start;
  align-items: flex-start;
    -webkit-flex-direction: column;
  flex-direction: column;
  height: 150px;
}
.lang_box_word {
  border-style:solid;
  border-width:1px;
  border-color:rgb(117,117,117);
  border-radius:5px;
  width:550px;
  height:50px;
  position: relative;
  top:0;
  transition: all .3s;
}
.lang_box_word:hover {
  border-color:rgb(0,139,245);
  top:-3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}
</style>

<script>
export default {
  // --- イベント関数 ---
  methods: {
    langSet: function (argLang) {
      var elm = document.getElementsByClassName('langCng')
      for (var i = 0; i < elm.length; i++) {
        // --- 選択された言語と一致は表示、その他は非表示 -------
        if (elm[i].getAttribute('lang') === argLang) {
          elm[i].style.display = ''
        } else {
          elm[i].style.display = 'none'
        }
      }
    }
  },
  mounted: function () {
    // --- ブラウザの設定言語を取得 ---
    var wDef = (window.navigator.browserLanguage || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage).substr(0, 2)
    // --- 日本語以外は英語を基本とする ---
    if (wDef !== 'ja') {
      wDef = 'en'
    }
    // --- 日本語か英語を表示させる ---
    this.langSet(wDef)
  }
}
</script>
