import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/EN/400error',
    name: 'EN_EN_400error',
    component: () => import('../components/EN/400error.vue')
  },
  {
    path: '/EN/403error',
    name: 'EN_403error',
    component: () => import('../components/EN/403error.vue')
  },
  {
    path: '/EN/404error',
    name: 'EN_404error',
    component: () => import('../components/EN/404error.vue')
  },
  {
    path: '/EN/493error',
    name: 'EN_493error',
    component: () => import('../components/EN/493error.vue')
  },
  {
    path: '/EN/540error',
    name: 'EN_540error',
    component: () => import('../components/EN/540error.vue')
  },
  {
    path: '/EN/545error',
    name: 'EN_545error',
    component: () => import('../components/EN/545error.vue')
  },
  {
    path: '/EN/552error',
    name: 'EN_552error',
    component: () => import('../components/EN/552error.vue')
  },
  {
    path: '/EN/TemplatePage',
    name: 'EN_TemplatePage',
    component: () => import('../components/EN/TemplatePage.vue')
  },
  {
    path: '/EN/ErrorMessage',
    name: 'EN_ErrorMessage',
    component: () => import('../components/EN/ErrorMessage.vue')
  },
  {
    path: '/EN/LoginPage',
    name: 'EN_LoginPage',
    component: () => import('../components/EN/LoginPage.vue')
  },
  {
    path: '/EN/ChangePasswordPage',
    name: 'EN_ChangePasswordPage',
    component: () => import('../components/EN/ChangePasswordPage.vue')
  },
  {
    path: '/EN/CannotgetError',
    name: 'EN_CannotgetError',
    component: () => import('../components/EN/CannotgetError.vue')
  },
  {
    path: '/EN/ErrTunnelConnectionFailed',
    name: 'EN_ErrTunnelConnectionFailed',
    component: () => import('../components/EN/ErrTunnelConnectionFailed.vue')
  },
  {
    path: '/EN/InvalidUsernameorPassword',
    name: 'EN_InvalidUsernameorPassword',
    component: () => import('../components/EN/InvalidUsernameorPassword.vue')
  },
  {
    path: '/EN/OTPPage',
    name: 'EN_OTPPage',
    component: () => import('../components/EN/OTPPage.vue')
  },
  {
    path: '/EN/NotAuthorized',
    name: 'EN_NotAuthorized',
    component: () => import('../components/EN/NotAuthorized.vue')
  },
  {
    path: '/EN/Applicationaccessdenied',
    name: 'EN_Applicationaccessdenied',
    component: () => import('../components/EN/Applicationaccessdenied.vue')
  },
  {
    path: '/EN/LoginPasswordChanged',
    name: 'EN_LoginPasswordChanged',
    component: () => import('../components/EN/LoginPasswordChanged.vue')
  },
  {
    path: '/EN/ChangePasswordhistory',
    name: 'EN_ChangePasswordhistory',
    component: () => import('../components/EN/ChangePasswordhistory.vue')
  },
  {
    path: '/EN/ChangePasswordinvalid',
    name: 'EN_ChangePasswordinvalid',
    component: () => import('../components/EN/ChangePasswordinvalid.vue')
  },
  {
    path: '/EN/ChangePasswordnotmatch',
    name: 'EN_ChangePasswordnotmatch',
    component: () => import('../components/EN/ChangePasswordnotmatch.vue')
  },
  {
    path: '/EN/ChangePasswordsame',
    name: 'EN_ChangePasswordsame',
    component: () => import('../components/EN/ChangePasswordsame.vue')
  },
  {
    path: '/EN/InvalidAuthenticationCode',
    name: 'EN_InvalidAuthenticationCode',
    component: () => import('../components/EN/InvalidAuthenticationCode.vue')
  },
  {
    path: '/EN/otpnotyouremail',
    name: 'EN_otpnotyouremail',
    component: () => import('../components/EN/otpnotyouremail.vue')
  },
  {
    path: '/EN/OTPemailhasnotarrived',
    name: 'EN_OTPemailhasnotarrived',
    component: () => import('../components/EN/OTPemailhasnotarrived.vue')
  },
  {
    path: '/EN/top',
    name: 'EN_top',
    component: () => import('../components/EN/top.vue')
  },
  {
    path: '/JA/400error',
    name: 'JA_JA_400error',
    component: () => import('../components/JA/400error.vue')
  },
  {
    path: '/JA/403error',
    name: 'JA_403error',
    component: () => import('../components/JA/403error.vue')
  },
  {
    path: '/JA/404error',
    name: 'JA_404error',
    component: () => import('../components/JA/404error.vue')
  },
  {
    path: '/JA/493error',
    name: 'JA_493error',
    component: () => import('../components/JA/493error.vue')
  },
  {
    path: '/JA/540error',
    name: 'JA_540error',
    component: () => import('../components/JA/540error.vue')
  },
  {
    path: '/JA/545error',
    name: 'JA_545error',
    component: () => import('../components/JA/545error.vue')
  },
  {
    path: '/JA/552error',
    name: 'JA_552error',
    component: () => import('../components/JA/552error.vue')
  },
  {
    path: '/JA/TemplatePage',
    name: 'JA_TemplatePage',
    component: () => import('../components/JA/TemplatePage.vue')
  },
  {
    path: '/JA/ErrorMessage',
    name: 'JA_ErrorMessage',
    component: () => import('../components/JA/ErrorMessage.vue')
  },
  {
    path: '/JA/LoginPage',
    name: 'JA_LoginPage',
    component: () => import('../components/JA/LoginPage.vue')
  },
  {
    path: '/JA/ChangePasswordPage',
    name: 'JA_ChangePasswordPage',
    component: () => import('../components/JA/ChangePasswordPage.vue')
  },
  {
    path: '/JA/CannotgetError',
    name: 'JA_CannotgetError',
    component: () => import('../components/JA/CannotgetError.vue')
  },
  {
    path: '/JA/ErrTunnelConnectionFailed',
    name: 'JA_ErrTunnelConnectionFailed',
    component: () => import('../components/JA/ErrTunnelConnectionFailed.vue')
  },
  {
    path: '/JA/InvalidUsernameorPassword',
    name: 'JA_InvalidUsernameorPassword',
    component: () => import('../components/JA/InvalidUsernameorPassword.vue')
  },
  {
    path: '/JA/OTPPage',
    name: 'JA_OTPPage',
    component: () => import('../components/JA/OTPPage.vue')
  },
  {
    path: '/JA/NotAuthorized',
    name: 'JA_NotAuthorized',
    component: () => import('../components/JA/NotAuthorized.vue')
  },
  {
    path: '/JA/LoginPasswordChanged',
    name: 'JA_LoginPasswordChanged',
    component: () => import('../components/JA/LoginPasswordChanged.vue')
  },
  {
    path: '/JA/ChangePasswordhistory',
    name: 'JA_ChangePasswordhistory',
    component: () => import('../components/JA/ChangePasswordhistory.vue')
  },
  {
    path: '/JA/ChangePasswordinvalid',
    name: 'JA_ChangePasswordinvalid',
    component: () => import('../components/JA/ChangePasswordinvalid.vue')
  },
  {
    path: '/JA/ChangePasswordnotmatch',
    name: 'JA_ChangePasswordnotmatch',
    component: () => import('../components/JA/ChangePasswordnotmatch.vue')
  },
  {
    path: '/JA/ChangePasswordsame',
    name: 'JA_ChangePasswordsame',
    component: () => import('../components/JA/ChangePasswordsame.vue')
  },
  {
    path: '/JA/InvalidAuthenticationCode',
    name: 'JA_InvalidAuthenticationCode',
    component: () => import('../components/JA/InvalidAuthenticationCode.vue')
  },
  {
    path: '/JA/otpnotyouremail',
    name: 'JA_otpnotyouremail',
    component: () => import('../components/JA/otpnotyouremail.vue')
  },
  {
    path: '/JA/OTPemailhasnotarrived',
    name: 'JA_OTPemailhasnotarrived',
    component: () => import('../components/JA/OTPemailhasnotarrived.vue')
  },
  {
    path: '/JA/top',
    name: 'JA_top',
    component: () => import('../components/JA/top.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
